require('slick-carousel');

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import '../stylesheets/home';
import '../stylesheets/carousel';

import * as youtubeComponet from './components/youtube';

import slickPrevImage from 'design/images/arrows/slider_arrow_left.png';
import slickNextImage from 'design/images/arrows/slider_arrow_right.png';

// ページ読み込み時の処理
document.addEventListener('turbolinks:load', function() {
  // メインカルーセル
  mainCarousel();
  // トピックスカルーセル
  topicsCarousel();
  // ブログを取得
  blogFeeds();
  // Youtubeのアクション
  youtubeComponet.playYoutubeOnModal();
})

// メインカルーセル
function mainCarousel () {
  $('.slick-box3').slick({
    centerPadding: '20%',
    dots: true,
    adaptiveHeight: true,
    centerMode: true,
    infinite: true, // 無限ループ
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 1500, // 切り替わりのスピード
    slidesToShow: 1, //通常 1024px以上の領域では表示する画像の数
    slidesToScroll: 1,
    prevArrow: '<img src="' + slickPrevImage + '" class="slider-arrow slick-prev" />',
    nextArrow: '<img src="' + slickNextImage + '" class="slider-arrow slick-next" />',
  });
}

// トピックスカルーセル
function topicsCarousel () {
  $('.slick-box4').slick({
    adaptiveHeight: true,
    infinite: true, // 無限ループ
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000, // 切り替わりのスピード
    slidesToShow: 4, //通常 1024px以上の領域では表示する画像の数
    slidesToScroll: 1,
    prevArrow: '<img src="' + slickPrevImage + '" class="slider-arrow slick-prev" />',
    nextArrow: '<img src="' + slickNextImage + '" class="slider-arrow slick-next" />',
    responsive: [
    {
      breakpoint: 1024,settings: { //601px～1024pxでは3画像表示
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,settings: { //481px～600pxでは2画像表示
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,settings: {//480px以下では1画像表示
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
}

// ブログを取得
function blogFeeds () {
  // ブログを表示する領域
  var section = $('section.blogs');
  // ajax通信で利用するURLを格納しているフィールド
  var hiddenField = $('#ajax_blogs_partial_top_page_path');

  // ブログ記事を取得
  $.ajax({
    url: hiddenField.val(),
    type: 'GET',
    dataType: 'html',
  })
  .done((respons) => {
    // 取得したブログ記事を追加するターゲット
    var target = section.find('div.c-topcol-r');
    // 取得したブログ記事を追加
    target.prepend(respons);
    // 取得したブログを表示
    target.show();
    // 不要なフィールドを削除
    hiddenField.remove();
  })
  .fail((jqXHR, textStatus, errorThrown) => {
    console.log('接続タイムアウトが発生しました。');
  })
}