import 'bootstrap/js/src/modal'

// モーダルを開く
export function modalOpen () {
  $('.modal').modal('show');

  $('.modal').on('hidden.bs.modal', function (e) {
    $('.modal').remove();
  })
}

// 空のモーダル要素を返す
export function blankModalElement (options={}) {
  // header
  var modalHeader = document.createElement('div');
  modalHeader.classList.add('modal-header');

  // title
  if (!!options['header']) {
    if (!!options['header']['title']) {
      var title = document.createElement('h5');
      title.innerHTML = options['header']['title'];
      title.classList.add('modal-title');
      modalHeader.append(title);
    }
  }

  // close button
  var closeButton = document.createElement('button');
  closeButton.type = 'button';
  closeButton.classList.add('close');
  closeButton.dataset.dismiss = 'modal';
  closeButton.ariaLabel = 'Close';
  var closeButton_span = document.createElement('span');
  closeButton_span.ariaHidden = 'true';
  closeButton_span.innerHTML = '&times;';
  closeButton.append(closeButton_span);
  modalHeader.append(closeButton)

  // body
  var modalBody = document.createElement('div');
  modalBody.classList.add('modal-body');
  if (!!options['body']) {
    if (!!options['body']['contents']) {
      modalBody.append(options['body']['contents']);
    }
  }

  // footer
  var modalFooter = document.createElement('div');
  modalFooter.classList.add('modal-footer');
  var modalFooterCloseButton = document.createElement('button');
  modalFooterCloseButton.type = 'button';
  modalFooterCloseButton.classList.add('btn', 'btn-secondary');
  modalFooterCloseButton.dataset.dismiss = 'modal';
  modalFooterCloseButton.ariaLabel = '閉じる';
  modalFooterCloseButton.innerText = '閉じる';
  modalFooter.append(modalFooterCloseButton);

  // content
  var modalContent = document.createElement('div');
  modalContent.classList.add('modal-content');
  modalContent.append(modalHeader);
  modalContent.append(modalBody);
  modalContent.append(modalFooter);

  // dialog
  var modalDialog = document.createElement('div');
  modalDialog.classList.add('modal-dialog', 'modal-xl');
  modalDialog.role = 'document';
  modalDialog.append(modalContent);

  // modal
  var modalElement = document.createElement('div');
  modalElement.classList.add('modal', 'fade');
  modalElement.tabindex = '-1';
  modalElement.role = 'dialog';
  modalElement.ariaHidden = 'true';
  modalElement.append(modalDialog);

  return modalElement
}