import * as modalComponet from './modal';

// youtubeのapiを叩いてiframeを指定の領域に埋め込む
export function onYouTubeIframeAPIReady (options = { playerVars: { autoplay: 0 }}) {
  // youtubeAPIを呼び出し
  var targets = document.querySelectorAll('.youtube-player');
  if (!!targets.length) {
    for (var i = 0; i < targets.length; i++) {
      var result = false;
      var target = targets[i];
      while (result == false) {
        result = apiReady(target, options);
      }
    }
  }
}

// youtubeのサムネイル画像のアクション
export function playYoutubeOnModal () {
  // 対象のサムネイル画像を探す
  var targets = document.querySelectorAll('div.movie-box img.thumbnail');
  if (!!targets.length) {
    for (var i = 0; i < targets.length; i++) {
      // モーダルを開いて再生
      playYoutube(targets[i]);

      // サムネイル画像にマウスが乗った時のアクション
      targets[i].addEventListener('mouseover', function() {
        // youtubeのアイコン要素
        var youtubeICON = this.parentNode.querySelector('img.play');
        if (!!youtubeICON) {
          youtubeICON.classList.add('d-none');
        }
      });

      // サムネイル画像からマウスが外れた時のアクション
      targets[i].addEventListener('mouseleave', function() {
        // youtubeのアイコン要素
        var youtubeICON = this.parentNode.querySelector('img.play');
        if (!!youtubeICON) {
          youtubeICON.classList.remove('d-none');
        }
      });
    }
  }

  // 対象のアイコンを探す
  var targets = document.querySelectorAll('div.movie-box img.play');
  if (!!targets.length) {
    for (var i = 0; i < targets.length; i++) {
      playYoutube (targets[i]);
    }
  }
}

// モーダル内でyoutube再生
function playYoutube (target) {
  target.addEventListener('click', function(event) {
    // 空のmodal要素を準備
    var youtubeIframeElement = document.createElement('div');
    youtubeIframeElement.classList.add('youtube-player');
    youtubeIframeElement.id = this.dataset.youtubeid;
    youtubeIframeElement.dataset.youtubeid = this.dataset.youtubeid;
    // 空のmodalに渡すoptionを設定
    var options = {
      header: {
        title: this.dataset.title
      },
      body: {
        contents: youtubeIframeElement
      }
    };
    // modalElementを準備
    var youtubeModalElement = modalComponet.blankModalElement(options);
    if (!!youtubeModalElement) {
      var modalElement = document.querySelector('.modal');
      if (!!modalElement) {
        modalElement.replaceWith(youtubeModalElement);
      } else {
        document.body.append(youtubeModalElement);
      }
    }
    // youtubeのAPIに渡すオプションを設定
    var youtubeApiOptions = {
      playerVars: {
        autoplay: 1
      }
    }
    // youtubeのAPI呼び出し
    onYouTubeIframeAPIReady(youtubeApiOptions);
    var modalBody = document.querySelector('.modal .modal-body');
    if (!!modalBody) {
      modalBody.classList.add('text-center');
    }
    // modalを開く
    modalComponet.modalOpen();
  });
}

function apiReady (target, _options = { playerVars: { autoplay: 0 }}) {
  var options = {
    videoId: target.dataset['youtubeid'],
    events: {
      'onError': returnFalse
    }
  }

  Object.assign(options, _options)

  var player = new YT.Player(target.id, options);
  return true
}

function returnFalse () {
  return false;
}